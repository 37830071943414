var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"start"}},[_c('v-dialog',{attrs:{"scrollable":"","max-width":"500px"},on:{"click:outside":_vm.closePop,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closePop.apply(null, arguments)}},model:{value:(_vm.dialogPwd),callback:function ($$v) {_vm.dialogPwd=$$v},expression:"dialogPwd"}},[_c('v-card',{staticClass:"rounded-max"},[_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('h3',[_vm._v("Password Setting")]),_c('v-spacer'),_c('v-btn',{attrs:{"fab":"","x-":"","small":"","depressed":""},on:{"click":_vm.closePop}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"px-2"},[_c('v-container',[(!_vm.second)?_c('div',[_c('p',{staticClass:"ma-0 mx-3"},[_vm._v("Select Type Password for scholar")]),_c('v-radio-group',{attrs:{"column":""},model:{value:(_vm.pwdType),callback:function ($$v) {_vm.pwdType=$$v},expression:"pwdType"}},[_c('v-card',{staticClass:"pa-2 mb-1"},[_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('v-radio',{staticClass:"mt-1",attrs:{"label":"Template","value":"template"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-tooltip',{attrs:{"top":"","color":"info","max-width":"200px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","depressed":"","x-small":"","color":"blue","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-information-variant")])],1)]}}],null,false,1696545020)},[_c('span',[_vm._v("Password based on default template or self-custom")])])],1)],1)],1),_c('v-card',{staticClass:"pa-2 mb-1"},[_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('v-radio',{staticClass:"mt-1",attrs:{"label":"Random","value":"random"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-tooltip',{attrs:{"top":"","color":"info","max-width":"200px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","depressed":"","x-small":"","color":"blue","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-information-variant")])],1)]}}],null,false,1696545020)},[_c('span',[_vm._v("Password in excel file will be filled randomly")])])],1)],1)],1),_c('v-card',{staticClass:"pa-2 mb-1"},[_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('v-radio',{staticClass:"mt-1",attrs:{"label":"From Excel","value":"input sendiri"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-tooltip',{attrs:{"top":"","color":"info","max-width":"200px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","depressed":"","x-small":"","color":"blue","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-information-variant")])],1)]}}],null,false,1696545020)},[_c('span',[_vm._v("The password is filled based on the excel file or will be filled based on template.")])])],1)],1)],1)],1)],1):_vm._e(),(_vm.pwdType == 'template' && _vm.page == 2)?_c('div',[_c('v-radio-group',{staticClass:"ma-0",model:{value:(_vm.template),callback:function ($$v) {_vm.template=$$v},expression:"template"}},[_c('v-card',{staticClass:"pa-2 mb-3"},[_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('v-text-field',{attrs:{"hide-details":"","solo":"","placeholder":"Custom"},model:{value:(_vm.customPwd),callback:function ($$v) {_vm.customPwd=$$v},expression:"customPwd"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('v-tooltip',{attrs:{"top":"","color":"info","max-width":"200px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","depressed":"","x-small":"","color":"blue","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-information-variant")])],1)]}}],null,false,1696545020)},[_c('span',[_vm._v("Scholars Get A Custom Password")])])],1)])],1)],1)],1)],1):_vm._e(),_c('div',[(_vm.second !== 'template' && _vm.second !== '')?_c('div',[_c('p',[_vm._v("Write Total Password to be Generated")]),_c('v-card',{staticClass:"pa-2 mb-3"},[_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('v-text-field',{attrs:{"hide-details":"","dense":"","solo":"","placeholder":"Type Number"},on:{"keyup":function($event){return _vm.isInt(_vm.count)}},model:{value:(_vm.count),callback:function ($$v) {_vm.count=_vm._n($$v)},expression:"count"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-tooltip',{attrs:{"top":"","color":"info","max-width":"200px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","depressed":"","x-small":"","color":"blue","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-information-variant")])],1)]}}],null,false,1696545020)},[_c('span',[_vm._v("Write Total Password to be Generated")])])],1)],1)],1)],1):_vm._e()]),_c('div',{staticClass:"d-flex justify-center mt-5"},[(_vm.pwdType && _vm.page == 1)?_c('v-btn',{attrs:{"depressed":"","color":"green","dark":""},on:{"click":function($event){return _vm.nextTo(_vm.pwdType)}}},[_vm._v("Next")]):_vm._e(),(_vm.pwdType == 'template' && _vm.page == 2)?_c('v-btn',{attrs:{"depressed":"","color":"green","dark":""},on:{"click":function($event){return _vm.nextTo(_vm.template, true)}}},[_vm._v("Next")]):_vm._e(),(_vm.isFinish && _vm.count)?_c('v-btn',{attrs:{"depressed":"","color":"green","dark":""},on:{"click":_vm.setPwd}},[_c('v-icon',[_vm._v("mdi-file-import")]),_vm._v("Submit")],1):_vm._e()],1)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }