<template>
  <v-row justify="start">
    <v-dialog
      v-model="dialogPwd"
      @click:outside="closePop"
      @keydown.esc="closePop"
      scrollable
      max-width="500px"
    >
      <v-card class="rounded-max">
        <v-toolbar dense flat>
          <h3>Password Setting</h3>
          <v-spacer></v-spacer>
          <v-btn fab x- small depressed @click="closePop"
            ><v-icon small>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text class="px-2">
          <v-container>
            <div v-if="!second">
              <p class="ma-0 mx-3">Select Type Password for scholar</p>
              <v-radio-group column v-model="pwdType">
                <v-card class="pa-2 mb-1">
                  <v-row>
                    <v-col cols="10">
                      <v-radio
                        class="mt-1"
                        label="Template"
                        value="template"
                      ></v-radio>
                    </v-col>
                    <v-col cols="2">
                      <v-tooltip top color="info" max-width="200px">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            fab
                            depressed
                            x-small
                            v-bind="attrs"
                            v-on="on"
                            color="blue"
                            dark
                            ><v-icon small
                              >mdi-information-variant</v-icon
                            ></v-btn
                          >
                        </template>
                        <span
                          >Password based on default template or
                          self-custom</span
                        >
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-card>
                <v-card class="pa-2 mb-1">
                  <v-row>
                    <v-col cols="10">
                      <v-radio
                        label="Random"
                        value="random"
                        class="mt-1"
                      ></v-radio>
                    </v-col>
                    <v-col cols="2">
                      <v-tooltip top color="info" max-width="200px">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            fab
                            depressed
                            x-small
                            v-bind="attrs"
                            v-on="on"
                            color="blue"
                            dark
                            ><v-icon small
                              >mdi-information-variant</v-icon
                            ></v-btn
                          >
                        </template>
                        <span
                          >Password in excel file will be filled randomly</span
                        >
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-card>
                <v-card class="pa-2 mb-1">
                  <v-row>
                    <v-col cols="10">
                      <v-radio
                        class="mt-1"
                        label="From Excel"
                        value="input sendiri"
                      ></v-radio>
                    </v-col>
                    <v-col cols="2">
                      <v-tooltip top color="info" max-width="200px">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            fab
                            depressed
                            x-small
                            v-bind="attrs"
                            v-on="on"
                            color="blue"
                            dark
                            ><v-icon small
                              >mdi-information-variant</v-icon
                            ></v-btn
                          >
                        </template>
                        <span
                          >The password is filled based on the excel file or
                          will be filled based on template.</span
                        >
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-card>
              </v-radio-group>
            </div>
            <div v-if="pwdType == 'template' && page == 2">
              <v-radio-group v-model="template" class="ma-0">
                <!-- <v-card class="pa-2 mb-1">
                  <v-row>
                    <v-col cols="10">
                      <v-radio
                        class="mt-1"
                        label="Default"
                        value="default"
                      ></v-radio>
                    </v-col>
                    <v-col cols="2">
                      <v-tooltip top color="info" max-width="200px">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            fab
                            depressed
                            x-small
                            v-bind="attrs"
                            v-on="on"
                            color="blue"
                            dark
                            ><v-icon small
                              >mdi-information-variant</v-icon
                            ></v-btn
                          >
                        </template>
                        <span
                          >All Scholars Get The Same Password:
                          "Teladan2021!"</span
                        >
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-card> -->
                <v-card class="pa-2 mb-3">
                  <v-row>
                    <v-col cols="10">
                      <v-text-field
                        hide-details
                        v-model="customPwd"
                        solo
                        placeholder="Custom"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <div class="d-flex align-center" style="height: 100%">
                        <v-tooltip top color="info" max-width="200px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              fab
                              depressed
                              x-small
                              v-bind="attrs"
                              v-on="on"
                              color="blue"
                              dark
                              ><v-icon small
                                >mdi-information-variant</v-icon
                              ></v-btn
                            >
                          </template>
                          <span>Scholars Get A Custom Password</span>
                        </v-tooltip>
                      </div>
                    </v-col>
                  </v-row>
                </v-card>
              </v-radio-group>
            </div>

            <div>
              <div v-if="second !== 'template' && second !== ''">
                <p>Write Total Password to be Generated</p>
                <v-card class="pa-2 mb-3">
                  <v-row>
                    <v-col cols="10">
                      <v-text-field
                        hide-details
                        dense
                        solo
                        v-model.number="count"
                        @keyup="isInt(count)"
                        placeholder="Type Number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-tooltip top color="info" max-width="200px">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            fab
                            depressed
                            x-small
                            v-bind="attrs"
                            v-on="on"
                            color="blue"
                            dark
                            ><v-icon small
                              >mdi-information-variant</v-icon
                            ></v-btn
                          >
                        </template>
                        <span>Write Total Password to be Generated</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-card>
              </div>
            </div>
            <div class="d-flex justify-center mt-5">
              <v-btn
                v-if="pwdType && page == 1"
                depressed
                color="green"
                @click="nextTo(pwdType)"
                dark
                >Next</v-btn
              >
              <v-btn
                v-if="pwdType == 'template' && page == 2"
                depressed
                color="green"
                @click="nextTo(template, true)"
                dark
                >Next</v-btn
              >
              <v-btn
                v-if="isFinish && count"
                @click="setPwd"
                depressed
                color="green"
                dark
                ><v-icon>mdi-file-import</v-icon>Submit</v-btn
              >
            </div>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "dialogDwonloadReport",

  props: ["dialogPwd"],
  data() {
    return {
      pwdType: "",
      template: "default",
      customPwd: "Teladan2021!",
      count: null,
      isFinish: false,
      second: "",
      page: 1,
    };
  },

  methods: {
    nextTo(val, status) {
      this.page++;
      this.second = val;
      this.isFinish = status;
      if (this.page == 2 && val !== "template") {
        this.isFinish = true;
      }
    },
    closePop() {
      this.$emit("close");
      this.pwdType = "";
      this.template = "default";
      this.customPwd = null;
      this.isFinish = false;
      this.second = "";
      this.page = 1;
      this.count = null;
    },
    isInt(val) {
      if (val !== undefined) {
        if (Number.isInteger(parseInt(val))) {
          this.count = parseInt(val);
        } else {
          this.count = null;
        }
      }
    },
    validate() {
      if (this.pwdType == "template") {
        if (this.template == "default") {
          this.setPwd("template", 0, "");
        } else {
          if (this.customPwd == "") {
            console.log("tidak boleh kosong");
          } else {
            this.setPwd("template", 0, this.customPwd);
          }
        }
      }
    },
    setPwd() {
      let data = {
        tipe: this.pwdType,
        total: this.count,
        password: this.customPwd !== "" ? this.customPwd : "Teladan2021!",
      };
      this.$emit("import", data);
      this.$emit("close");
      setTimeout(() => {
        this.pwdType = "";
        this.template = "default";
        this.customPwd = "";
        this.isFinish = false;
        this.second = "";
        this.page = 1;
        this.count = null;
      }, 200);
    },
  },
};
</script>
<style scoped>
.no_decoration {
  text-decoration: none;
}
</style>
